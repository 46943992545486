import React, {useEffect} from 'react'

const About = () => {
    useEffect(()=>{
document.title = "Our Approach | Lyn Partners in Community Empowerment (PACER)";

},[]);
  return (
       <>
       <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(24, 40, 66, 0.5), rgba(8, 6, 4, 0.5)), url("./images/17.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
             <h2 className='quote-h2'>Our Approach</h2>
               <h3 className="category-h3 category-h3-a category-h3-c">Scenario-based training and gender-transformative training in schools, TVETs, and communities.</h3>
            {/* <div className='pledge-line'></div> */}
         {/* <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>
 Contributing to Uganda the exudes innovative, high-quality education, skills and other social services.
</p> */}
            
        </div>
    </section>
    <section className="section-cover section-cover-b">
        {/* <h2 className="home-h2 wwa-h2 wwa-h2-a">Our Approach</h2>
        <h3 className="category-h3 category-h3-a">Scenario-based training and gender-transformative training in schools, TVETs, and communities.</h3> */}
        <div className="catergories-container">
                           <article className="category">
                             <div className="category-image">
                                    {/* <img src="./images/26.jpg" alt="Image" className="category-photo"/> */}
                                    <img src="./images/16.jpg" alt="Image" className="category-photo"/>
                             </div>

                             <div className="category-content">
                                    <h3 className="category-h3 category-h3-b">In school empowerment</h3> 
                                    <p className="category-p">We partner with schools (learners, teachers, school leaders, and other stakeholders) to enhance workforce readiness, entrepreneurship, and life skills. We partner with governments to support the integration of evidence-based skills education into national education systems and invest in the continued assessment of this approach.</p>   
                                    
                                    {/* <a href="store.html" className="category-link">See More</a> */}
                                    {/* <Link to='office-furniture' className="category-link" onClick={scrollFunc}>See More</Link> */}

                            </div>


                           </article>

                           <article className="category">
                            <div className="category-image">
                                   {/* <img src="./images/21.jpg" alt="Image" className="category-photo"/> */}
                             <img src="./images/9.jpg" alt="Image" className="category-photo"/>
                            </div>

                            <div className="category-content">
                                   <h3 className="category-h3 category-h3-b">Out-of-school empowerment</h3> 
                                   <p className="category-p">We create and conduct industry-specific, climate-smart, and group-specific (girls, women, and marginalised youth) interventions through community groups and apprenticeships to equip out-of-school youth with the skills to successfully transition to quality employment preferably self-employment.</p>   
                                   
                                   {/* <a href="store.html" className="category-link">See More</a> */}
 {/* <Link to='home-furniture' className="category-link" onClick={scrollFunc}>See More</Link> */}
                           </div>


                          </article>

                          <article className="category">
                            <div className="category-image">
                                   <img src="./images/45.jpg" alt="Image" className="category-photo"/>
                            </div>

                            <div className="category-content">
                                   <h3 className="category-h3 category-h3-b">Skilling TVET</h3> 
                                   <p className="category-p">We partner with TVET institutions to train instructors and learners on scenario-based learning and gender equality.</p>   
                                   
                                   {/* <a href="store.html" className="category-link">See More</a> */}

                           </div>


                          </article>
                 </div>
    </section>
    </>
  )
}
export default About