import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import VisionCarousel from '../components/Vision Carousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import AC from '../components/AC';
import Painting from '../components/Painting';
import Doors from '../components/Doors';
import Al from '../components/Al';
import Carpentry from '../components/Carpentry';
import Tile from '../components/Tile';
import Cleaning from '../components/Cleaning';
import Supply from '../components/Supply';
import GS from '../components/GS';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});
}
const Services = () => {

useEffect(()=>{
document.title = "Our Programmes | Lyn Partners in Community Empowerment (PACER)";

},[]);


  return (
    <>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(24, 40, 66, 0.5), rgba(8, 6, 4, 0.5)), url("./images/29.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
             <h2 className='quote-h2'>Our Programmes</h2>
            {/* <div className='pledge-line'></div> */}
         {/* <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>
 Contributing to Uganda the exudes innovative, high-quality education, skills and other social services.
</p> */}
            
        </div>
    </section>
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">Our Programmes</h2> */}
           <div className='content-container services-container'>
              <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Education and Skilling</h3>
                    <p>Skills development builds a strong foundation for learners in school and after school. It builds self-esteem, resilience, confidence, self-awareness, and leadership skills. It develops critical thinking, problem-solving skills, and collaboration.</p>
                     <Link to='/education-skilling-projects' className='home-btn' onClick={scrollFunc}>See Projects</Link>
                  </article>

                  <article className='service-slides'>
                    <TelecomCarousel></TelecomCarousel>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Gender Transformative Education</h3>
                        <p>Gender Transformative Education seeks to utilize all parts of an education system to transform stereotypes, attitudes, norms, and practices by challenging power relations, rethinking gender norms, and raising critical consciousness about the root causes of inequality and discrimination. Educating girls to the same level as boys promotes growth and development in all aspects of life.</p>
                     <Link to='/gender-transformative-education-projects' className='home-btn' onClick={scrollFunc}>See Projects</Link>
                  </article>

                  <article className='service-slides'>
                    <PowerCarousel></PowerCarousel>
                 
                  </article>     
            </div>

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Community Mobile Skilling</h3>
                       
                       <p>
             Communities face various challenges, including high unemployment rates, social exclusion, child abuse and neglect, insufficient quality education, hunger, low household income, disease, and the effects of global warming. Many factors impede young girls and boys from attending school. For that reason, PACER partners with local authorities to train youth in entrepreneurial skills in the community.
                     
                     </p>
                      <Link to='/community-mobile-skilling-projects' className='home-btn' onClick={scrollFunc}>See Projects</Link>
                     {/* <a href='/rental-services' className='home-btn'>Read More</a> */}

                  </article>

                  <article className='service-slides'>
                    <RoadCarousel></RoadCarousel>
                 
                  </article>     
            </div>
             <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Local Farmers' Empowerment</h3>
                        <p>In collaboration with local authorities, PACER will enhance sustainable agricultural practices and empower local farming communities through
improved seed varieties, mechanized farming, value addition, agroforestry initiatives, leading to
improved food security, environmental conservation, and economic resilience. It will adopt climate-smart and eco-friendly farming techniques that contribute to increased crop yields, reduced environmental impact, and enhanced livelihoods.</p>
                     {/* <Link to='/' className='home-btn' onClick={scrollFunc}>See Projects</Link> */}
                  </article>

                  <article className='service-slides'>
                    <SupportCarousel></SupportCarousel>
                 
                  </article>     
            </div>

          
           </div>

    </section>
    </>
  )
}

export default Services