import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import VisionCarousel from '../components/Vision Carousel';
import MissionCarousel from '../components/Mission Carousel';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaArrowAltCircleRight,FaHandshake,FaCheck,FaQuoteLeft, FaCalendarPlus,FaCertificate, FaUsers, FaThumbsUp,FaFileAlt} from 'react-icons/fa';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "Community Mobile Skilling Projects | Lyn Partners in Community Empowerment (PACER)";

},[]);

  return (
    <> 
 <section className='section-cover section-cover1 benefits-cover'>
       <h3 className='title-h3 service-h3'>Community Mobile Skilling Projects</h3>
       <div className='content-container goals-container'>
          <article className='goals-slides'>
               {/* <img src='images/24.jpg' className='scope-image'></img> */}
                <img src='images/6.jpg' className='scope-image'></img>
           </article>

           <article className='goals-slides'>
               {/* <img src='images/23.jpg' className='scope-image'></img> */}
               <img src='images/9.jpg' className='scope-image'></img>
           </article>
       </div>

  </section>
  
    <section className='section-cover bg-cover bg-cover-a'>
        <div className='content-container '>
                 <p className='home-p'>
        <strong>Skills vans:</strong> PACER conducts community mobile trainings. Due to financial constraints, youth are unable to join formal training. That is why PACER in collaboration with local authorities find the youth in their localities. The youth are trained on identified business ideas after a needs assessment and business pitching from the youth. The youth are trained twice a week for 3 months. After completion, toolkits are provided to the youth after satisfying the required set standards. For sustainability, accountability of the business is reported to the LC1 committee once a month for two years.
                </p>
                <p className='home-p'>
        <strong>Village apprenticeship:</strong> In collaboration with local authorities, PACER identifies operating businesses and artisans to which youth are attached for experiential learning. These include carpentry workshops, salons, restaurants, welding shops, and agricultural businesses.
                </p>
                <p className='home-p'>
      <strong>Youth psychosocial support groups:</strong> Started during the COVID-19 by concerned youth who volunteered to offer peer-to-peer support to colleagues that were affected by the yearlong lockdown.  It came after a group of youth realised that their peers were on the verge of mental breakdown. As a result, they moved from home to home offering counselling sessions to willing youth. During the lockdown, some youth exhibited increased risk-taking behaviour in the form of failing to follow social distancing protocols, alcohol or substance abuse, or an increase in self-harm as a means of coping. There were also rampant incidences of rape cases, forced marriages, and unwanted pregnancies.

                </p>
                

        </div>

    </section>

  {/* <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(24, 40, 66, 0.62), rgba(8, 6, 4, 0.81)), url("./images/16.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
             <h2 className='quote-h3'>Our Vision</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>
 Contributing to Uganda the exudes innovative, high-quality education, skills and other social services.
</p>
            <h2 className='quote-h3'>Our Vision</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>
 To learn, practice and innovate.
</p>
        </div>
    </section> */}
    {/* <section className='section-cover bg-cover'>
        <div className='content-container '>
                
<p className='home-p'>In accordance with the national need of technical training for economic development, and the way it has been seen by society in the past few years, our aim is to inform, educate and train youth in Uganda. We aim at facilitating a belief that technical training will offer youth and women the chance to secure jobs faster, to be self-reliant, establish their own business and in turn, commit to a more systemized change in Uganda, starting from youth and women who believe, who can, who do and at the core of our work, it is a partnership for sustainable development, which is based on three directions as listed below:
 </p>
 <ol className='home-ul' type='a'>
 <li>Creating an infrastructure of technical training within the Ugandan Educational system.</li> 
<li>Facilitating access to technical training by providing training to youth and women outside school.</li> 
<li>Increasing international awareness and collaborating with companies and organizations that socially and economically want to invest, improve and empower youth and women lives for attaining sustainable development in Uganda.</li>

 </ol>

<p className='home-p'>We are a team of people committed to equip youth and women in Uganda with hands on skills for their everyday life. We believe that personal development and education are the way to end poverty and we are advocating this and a better future for youth and women in Uganda through all the activities and projects we do.</p>
  
        </div>

    </section> */}


   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2>
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'></div>
   
    <div className='home-services-container '>
      <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div>
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Quality</h3>
          <p> 	Our business is 100% Ugandan owned.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaFileAlt></FaFileAlt></span>
          </div>
          <h3 className='home-service-title'>Professionalism</h3>
          <p> 	Our teaching staff are qualified with a wealth of professional knowledge.</p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Partnership</h3>
          <p> 	We have a hands-on approach to learning, with a 70% practical skills focus.</p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaThumbsUp></FaThumbsUp></span>
          </div>
          <h3 className='home-service-title'>Durability</h3>
          <p>
            	We offer an end-to-end training solution for all types of clients.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Trust</h3>
          <p> 	We are accredited with all the relevant accreditation institutions.</p>
        </div>

    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section> */}

  {/* <section className='section-cover bg-cover'>
        <div className='content-container '>
          <h2 className="home-h2">Course Content Is Tailor-Made To Suit</h2>
          <div className='line1'>
                    <div className='line2'></div>
          </div>      

 <ol className='home-ul'>
<li>Artisans already employed in the industry, and wanting to improve their skills.</li>
<li>Apprentices readying themselves to do their trade test.</li>
<li>People that want to change careers.</li>
<li>Individuals who had to withdraw from school but show an interest in and have the ability to work well with their hands.</li>
<li>	College and University students who need to gain practical experience.</li>
<li>School leavers that want to specialize in a technical skill or trade; who have left school with no future plans in place yet. They now have the opportunity to evaluate all the trade options and choose the course and career prospects that appeals to them. A little entrepreneurial spirit combined with the right course will go a long way towards building a prosperous and successful private business.</li>
<li>Employees who have been sent by their respective employers to improve on their skills. Courses are tailor-made to suit specific requirements of the employer’s wide range of needs through practical training courses.</li>


 </ol>

<p className='home-p'>We are a team of people committed to equip youth and women in Uganda with hands on skills for their everyday life. We believe that personal development and education are the way to end poverty and we are advocating this and a better future for youth and women in Uganda through all the activities and projects we do.</p>
  
        </div>

    </section> */}
    
    
  
    </>
  )
}

export default Home