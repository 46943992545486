import Carousel from 'react-bootstrap/Carousel';

function SupportCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/35.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/36.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/43.jpeg"
          alt="Third slide"
        />
      </Carousel.Item>
          <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/44.jpeg"
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default SupportCarousel;