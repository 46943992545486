import React from 'react';
import {FaWhatsapp} from 'react-icons/fa'

const WhatsAppIcon = () => {
  return (<div>

    <a
        href="https://wa.me/+256706840592"
        className="whatsapp_float"
        target="_blank"
        // rel="noopener noreferrer"
        rel="noreferrer"
      >
        {/* <i class="fa fa-whatsapp whatsapp-icon"></i> */}
        <FaWhatsapp className='whatsapp-icon'></FaWhatsapp>
      </a>

    </div>
  )
}

export default WhatsAppIcon