import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import VisionCarousel from '../components/Vision Carousel';
import MissionCarousel from '../components/Mission Carousel';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaArrowAltCircleRight,FaHandshake,FaCheck,FaQuoteLeft, FaCalendarPlus,FaCertificate, FaUsers, FaThumbsUp,FaFileAlt} from 'react-icons/fa';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "Gender Transformative Education Projects | Lyn Partners in Community Empowerment (PACER)";

},[]);

  return (
    <> 
 <section className='section-cover section-cover1 benefits-cover'>
       <h3 className='title-h3 service-h3'>Gender Transformative Education Projects</h3>
       <div className='content-container goals-container'>
          <article className='goals-slides'>
               {/* <img src='images/22.jpg' className='scope-image'></img> */}
               <img src='images/47.jpg' className='scope-image'></img>
           </article>

           <article className='goals-slides'>
               {/* <img src='images/20.jpg' className='scope-image'></img> */}
               <img src='images/5.jpg' className='scope-image'></img>
           </article>
       </div>

  </section>
  
    <section className='section-cover bg-cover bg-cover-a'>
        <div className='content-container '>
                 <p className='home-p'>
        <strong>Girls Can project:</strong> Under our Girls Can project, we move the teachers, school leaders, district, and parents to a realization that when girls are empowered to their unlimited potential they can contribute to the development of communities and countries. It tackles the norms and stereotypes that enslave women. All the stakeholders are provoked to create an enabling environment for girls to thrive in a male-dominated society by ensuring gender justice. 
                </p>
                <p className='home-p'>
        <strong>Gender-responsive pedagogy:</strong> Gender norms are extremely challenging to address because they are entrenched in every aspect of society. In the classroom, a teacher has the power to diffuse all harmful gender norms and power relations in teaching practices, curricula, and teaching materials.
                </p>
                <p className='home-p'>
       <strong>Leaving No Girl Behind project:</strong>  We implement this project on two levels; 
                </p>
                <ul>
                    <li>
Community sensitisation to support the girl in accessing technical and vocational education. 
                    </li>
                    <li>
    Gender transformative education for the TVET instructors and principals and TVET department of MoES. The project serves as a call from the young girls and women who are unable to access vocational education and those who are already in Vocational and Technical Institutions but are being discriminated against on grounds of gender norms and stereotypes.  
                    </li>
                </ul>

        </div>

    </section>

  {/* <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(24, 40, 66, 0.62), rgba(8, 6, 4, 0.81)), url("./images/16.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
             <h2 className='quote-h3'>Our Vision</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>
 Contributing to Uganda the exudes innovative, high-quality education, skills and other social services.
</p>
            <h2 className='quote-h3'>Our Vision</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>
 To learn, practice and innovate.
</p>
        </div>
    </section> */}
    {/* <section className='section-cover bg-cover'>
        <div className='content-container '>
                
<p className='home-p'>In accordance with the national need of technical training for economic development, and the way it has been seen by society in the past few years, our aim is to inform, educate and train youth in Uganda. We aim at facilitating a belief that technical training will offer youth and women the chance to secure jobs faster, to be self-reliant, establish their own business and in turn, commit to a more systemized change in Uganda, starting from youth and women who believe, who can, who do and at the core of our work, it is a partnership for sustainable development, which is based on three directions as listed below:
 </p>
 <ol className='home-ul' type='a'>
 <li>Creating an infrastructure of technical training within the Ugandan Educational system.</li> 
<li>Facilitating access to technical training by providing training to youth and women outside school.</li> 
<li>Increasing international awareness and collaborating with companies and organizations that socially and economically want to invest, improve and empower youth and women lives for attaining sustainable development in Uganda.</li>

 </ol>

<p className='home-p'>We are a team of people committed to equip youth and women in Uganda with hands on skills for their everyday life. We believe that personal development and education are the way to end poverty and we are advocating this and a better future for youth and women in Uganda through all the activities and projects we do.</p>
  
        </div>

    </section> */}


   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2>
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'></div>
   
    <div className='home-services-container '>
      <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div>
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Quality</h3>
          <p> 	Our business is 100% Ugandan owned.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaFileAlt></FaFileAlt></span>
          </div>
          <h3 className='home-service-title'>Professionalism</h3>
          <p> 	Our teaching staff are qualified with a wealth of professional knowledge.</p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Partnership</h3>
          <p> 	We have a hands-on approach to learning, with a 70% practical skills focus.</p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaThumbsUp></FaThumbsUp></span>
          </div>
          <h3 className='home-service-title'>Durability</h3>
          <p>
            	We offer an end-to-end training solution for all types of clients.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Trust</h3>
          <p> 	We are accredited with all the relevant accreditation institutions.</p>
        </div>

    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section> */}

  {/* <section className='section-cover bg-cover'>
        <div className='content-container '>
          <h2 className="home-h2">Course Content Is Tailor-Made To Suit</h2>
          <div className='line1'>
                    <div className='line2'></div>
          </div>      

 <ol className='home-ul'>
<li>Artisans already employed in the industry, and wanting to improve their skills.</li>
<li>Apprentices readying themselves to do their trade test.</li>
<li>People that want to change careers.</li>
<li>Individuals who had to withdraw from school but show an interest in and have the ability to work well with their hands.</li>
<li>	College and University students who need to gain practical experience.</li>
<li>School leavers that want to specialize in a technical skill or trade; who have left school with no future plans in place yet. They now have the opportunity to evaluate all the trade options and choose the course and career prospects that appeals to them. A little entrepreneurial spirit combined with the right course will go a long way towards building a prosperous and successful private business.</li>
<li>Employees who have been sent by their respective employers to improve on their skills. Courses are tailor-made to suit specific requirements of the employer’s wide range of needs through practical training courses.</li>


 </ol>

<p className='home-p'>We are a team of people committed to equip youth and women in Uganda with hands on skills for their everyday life. We believe that personal development and education are the way to end poverty and we are advocating this and a better future for youth and women in Uganda through all the activities and projects we do.</p>
  
        </div>

    </section> */}
    
    
  
    </>
  )
}

export default Home